var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"item",on:{"click":function($event){$event.preventDefault();return _vm.goDetails(_vm.itemInfo)}}},[_c('div',{staticClass:"i-top"},[(_vm.itemInfo.commoditiesPicture)?_c('img',{staticClass:"i-pic",attrs:{"src":_vm.itemInfo.commoditiesPicture[0],"alt":""}}):_c('img',{staticClass:"i-pic",attrs:{"src":require('@/assets/images/placeholder/banner.png'),"alt":""}}),(_vm.itemInfo.limitedGrade)?_c('img',{staticClass:"pic-vip",attrs:{"src":require('@/assets/images/Public/vip.png'),"alt":""}}):_vm._e(),(_vm.itemInfo.remainStock == 0)?_c('div',{staticClass:"i-pic pic-box"}):_vm._e(),(_vm.itemInfo.remainStock == 0)?_c('img',{staticClass:"pic-c",attrs:{"src":require('@/assets/images/Mall/c1.png'),"alt":""}}):_vm._e(),(_vm.itemInfo.birthdayPrivileges)?_c('img',{staticClass:"pic-b",attrs:{"src":require('@/assets/images/Mall/b1.png'),"alt":""}}):_vm._e()]),_c('div',{staticClass:"i-main"},[_c('div',{staticClass:"i-title two-omit"},[_vm._v(" "+_vm._s(_vm.itemInfo.commodityTitle)+" ")]),_c('span',{staticClass:"i-name"},[_vm._v(_vm._s(_vm.itemInfo.commodityLabel))]),(_vm.itemInfo.lowestMemberPrices)?_c('div',{staticClass:"i-price"},[(
          _vm.itemInfo.lowestMemberPrices[0].amount === 0 &&
          _vm.itemInfo.lowestMemberPrices[0].integral === 0 &&
          _vm.itemInfo.lowestMemberPrices[0].printing === 0
        )?[_c('span',[_vm._v(_vm._s(_vm.itemInfo.lowestMemberPrices[0].amount))]),_c('span',{staticClass:"unit"},[_vm._v("元")]),(_vm.itemInfo.lowestMemberPrices[0].isLow === '1')?_c('span',{staticClass:"low"},[_vm._v("起")]):_vm._e()]:[(Number(_vm.itemInfo.lowestMemberPrices[0].integral) > 0)?_c('span',[_vm._v(" "+_vm._s(_vm.itemInfo.lowestMemberPrices[0].integral)),_c('span',{staticClass:"unit"},[_vm._v("积分")])]):_vm._e(),(
            Number(_vm.itemInfo.lowestMemberPrices[0].integral) > 0 &&
            Number(_vm.itemInfo.lowestMemberPrices[0].printing) > 0
          )?_c('span',[_vm._v("+")]):_vm._e(),(Number(_vm.itemInfo.lowestMemberPrices[0].printing) > 0)?_c('span',[_vm._v(_vm._s(_vm.itemInfo.lowestMemberPrices[0].printing)),_c('span',{staticClass:"unit"},[_vm._v("印花")])]):_vm._e(),(
            (Number(_vm.itemInfo.lowestMemberPrices[0].integral) ||
              Number(_vm.itemInfo.lowestMemberPrices[0].printing)) > 0 &&
            Number(_vm.itemInfo.lowestMemberPrices[0].amount) > 0
          )?_c('span',[_vm._v("+")]):_vm._e(),(
            _vm.itemInfo.lowestMemberPrices[0].amount &&
            _vm.itemInfo.lowestMemberPrices[0].amount > 0
          )?[_c('span',[_vm._v(_vm._s(_vm.itemInfo.lowestMemberPrices[0].amount))]),_c('span',{staticClass:"unit"},[_vm._v("元")])]:_vm._e(),(_vm.itemInfo.lowestMemberPrices[0].isLow === '1')?_c('span',{staticClass:"low"},[_vm._v("起 ")]):_vm._e()]],2):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }