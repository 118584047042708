import { render, staticRenderFns } from "./ShowItem.vue?vue&type=template&id=068148bd&scoped=true"
import script from "./ShowItem.vue?vue&type=script&lang=ts"
export * from "./ShowItem.vue?vue&type=script&lang=ts"
import style0 from "./ShowItem.vue?vue&type=style&index=0&id=068148bd&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "068148bd",
  null
  
)

export default component.exports