
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Row, Image, Col, Icon } from "vant";
import ProButton from "@/views/ProjectManagement/components/ProButton.vue";
import ShowItem from "@/views/ProjectManagement/components/ShowItem.vue";
@Component({
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Image.name]: Image,
    [Icon.name]: Icon,
    ProButton,
    ShowItem,
  },
})
export default class ShowList extends Mixins(Mixin) {
  @Prop({
    type: Array,
    default: () => {
      return [];
    },
  })
  public recommendList!: Array<good.RecommendProductDto>;
  @Watch("recommendList", { immediate: true, deep: true })
  updateClassify(): void {
    console.log(333, this.recommendList);

    this.leftHight = 0;
    this.rightHight = 0;
    this.leftList = [];
    this.rightList = [];
    this.getRecommended();
  }
  buttonInfo = { upward: false };
  isShow = false;
  leftList: Array<good.RecommendProductDto> = [];
  rightList: Array<good.RecommendProductDto> = [];
  leftHight = 0;
  rightHight = 0;
  getRecommended(): void {
    //定义两个临时的变量来记录左右两栏的高度，避免频繁调用setData方法
    let leftH = this.leftHight;
    let rightH = this.rightHight;
    let leftData = [];
    let rightData = [];
    let imgHeight = 480;
    for (let i = 0; i < this.recommendList.length; i++) {
      let obj = this.recommendList[i].productNameShort;
      let titleHeight = Math.ceil(Number(obj!.length - 1) / 10) * 28;
      let timeHight = 30;
      let couponsLabelHight = this.recommendList[i].couponsLabel ? 26 : 0;
      let money = 30;
      let currentItemHeight =
        titleHeight + timeHight + couponsLabelHight + money + imgHeight; //当前card整个的高
      if (leftH == rightH || leftH < rightH) {
        //判断左右两侧当前的累计高度，来确定item应该放置在左边还是右边
        leftData.push(this.recommendList[i]);
        leftH += currentItemHeight;
      } else {
        rightData.push(this.recommendList[i]);
        rightH += currentItemHeight;
      }
    }

    leftData = this.leftList.concat(leftData);
    rightData = this.rightList.concat(rightData);
    // console.log("rightData",rightData);
    //更新左右两栏的数据以及累计高度
    this.leftList = leftData;
    this.rightList = rightData;
    this.leftHight = leftH;
    this.rightHight = rightH;
  }
  goDetails(itemInfo: good.RecommendProductDto): void {
    this.$emit("goDetails", itemInfo);
  }
}
