
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Image, Swipe, SwipeItem, Icon } from "vant";

@Component({
  components: {
    [Image.name]: Image,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Icon.name]: Icon,
  },
})
export default class Briefing extends Mixins(Mixin) {
  @Prop({
    type: Object,
    default: () => {
      return {
        upward: false,
      };
    },
  })
  public buttonInfo!: { upward: boolean };
  @Prop({
    type: Number,
    default: 1,
  })
  public type!: number;
  onClick(): void {
    this.buttonInfo.upward = !this.buttonInfo.upward;
  }
}
