
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Image, Swipe, SwipeItem, Tab, Tabs } from "vant";
import ResultList from "@/components/showProList.vue";
import onlyMall from "./Recommends/onlyMall.vue";
import onlyShow from "./Recommends/onlyShow.vue";

@Component({
  components: {
    [Image.name]: Image,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    ResultList,
    onlyMall,
    onlyShow,
  },
})
export default class Recommend extends Mixins(Mixin) {
  @Prop({
    type: String,
    default: "",
  })
  public contentImg!: string; //题目图片
  @Prop({
    type: Boolean,
    default: false,
  })
  public contentType!: boolean; //判断单列还是瀑布流
  @Prop({
    type: String,
    default: "",
  })
  public titleColor!: string;
  active = 0;
}
