
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Image, Swipe, SwipeItem } from "vant";
import ProButton from "@/views/ProjectManagement/components/ProButton.vue";
@Component({
  components: {
    [Image.name]: Image,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    ProButton,
  },
})
export default class Briefing extends Mixins(Mixin) {
  @Prop({
    type: String,
    default: "",
  })
  public introduceMessage!: string;
  @Prop({
    type: String,
    default: "",
  })
  public titleColor!: string;
  @Prop({
    type: String,
    default: "",
  })
  public contentImg!: string;
  buttonInfo = { upward: true };
  isShow = false;
  mounted(): void {
    this.dealInfo();
  }
  dealContent(introduceMessage: string): string {
    let text = "";
    if (introduceMessage.includes("<img")) {
      text = introduceMessage.replace(/<img /g, '<img class="rich-img" ');
    } else {
      text = introduceMessage;
    }
    return text;
  }
  dealInfo(): void {
    console.log(
      (document.getElementById("briefing") as HTMLElement).offsetHeight
    );
    let height = (document.getElementById("briefing") as HTMLElement)
      .offsetHeight;
    if (height <= 270) {
      this.isShow = false;
      this.buttonInfo.upward = true;
    } else {
      this.isShow = true;
      this.buttonInfo.upward = false;
    }
  }
}
