var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"p-item",on:{"click":function($event){$event.preventDefault();return _vm.goDetails(_vm.itemInfo)}}},[_c('van-row',{staticClass:"h-main"},[_c('div',{staticClass:"i-top"},[(_vm.itemInfo.commoditiesPicture)?_c('img',{staticClass:"p-h-pic",attrs:{"src":_vm.itemInfo.commoditiesPicture[0]}}):_vm._e(),(_vm.itemInfo.limitedGrade)?_c('img',{staticClass:"pic-vip",attrs:{"src":require('@/assets/images/Public/vip.png'),"alt":""}}):_vm._e(),(_vm.itemInfo.birthdayPrivileges)?_c('img',{staticClass:"pic-b",attrs:{"src":require('@/assets/images/Mall/b1.png'),"alt":""}}):_vm._e(),(_vm.itemInfo.remainStock == 0)?_c('div',{staticClass:"i-pic pic-box"}):_vm._e(),(_vm.itemInfo.remainStock == 0)?_c('img',{staticClass:"pic-c",attrs:{"src":require('@/assets/images/Mall/c1.png'),"alt":""}}):_vm._e()]),_c('van-row',{staticClass:"h-m-right"},[_c('div',{staticClass:"t-one two-omit"},[_vm._v(" "+_vm._s(_vm.itemInfo.commodityTitle)+" ")]),(_vm.itemInfo.commodityLabel)?_c('div',{staticClass:"t-name"},[_vm._v(" "+_vm._s(_vm.itemInfo.commodityLabel)+" ")]):_vm._e(),(_vm.itemInfo.lowestMemberPrices)?_c('div',{staticClass:"t-price"},[(
            Number(_vm.itemInfo.lowestMemberPrices[0].amount) === 0 &&
            Number(_vm.itemInfo.lowestMemberPrices[0].integral) === 0 &&
            Number(_vm.itemInfo.lowestMemberPrices[0].printing) === 0
          )?[_c('span',[_vm._v(_vm._s(_vm.itemInfo.lowestMemberPrices[0].amount))]),_c('span',{staticClass:"unit"},[_vm._v("元")])]:_vm._e(),(
            _vm.itemInfo.lowestMemberPrices[0].amount &&
            _vm.itemInfo.lowestMemberPrices[0].amount > 0
          )?[_c('span',[_vm._v(_vm._s(_vm.itemInfo.lowestMemberPrices[0].amount.toFixed(2).split(".")[0]))]),_c('span',{staticClass:"unit"},[_vm._v("."+_vm._s(_vm.itemInfo.lowestMemberPrices[0].amount.toFixed(2).split(".")[1])+"元")])]:_vm._e(),(
            Number(_vm.itemInfo.lowestMemberPrices[0].amount) > 0 &&
            Number(_vm.itemInfo.lowestMemberPrices[0].integral) > 0
          )?_c('span',[_vm._v(" + ")]):_vm._e(),(Number(_vm.itemInfo.lowestMemberPrices[0].integral) > 0)?_c('span',[_vm._v(" "+_vm._s(_vm.itemInfo.lowestMemberPrices[0].integral)),_c('span',{staticClass:"unit"},[_vm._v("积分")])]):_vm._e(),(
            Number(_vm.itemInfo.lowestMemberPrices[0].integral) > 0 &&
            Number(_vm.itemInfo.lowestMemberPrices[0].printing) > 0
          )?_c('span',[_vm._v(" + ")]):_vm._e(),(Number(_vm.itemInfo.lowestMemberPrices[0].printing) > 0)?_c('span',[_vm._v(" "+_vm._s(_vm.itemInfo.lowestMemberPrices[0].printing)),_c('span',{staticClass:"unit"},[_vm._v("印花")])]):_vm._e()],2):_vm._e()])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }