
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Row, Image, Col, Icon } from "vant";

@Component({
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Image.name]: Image,
    [Icon.name]: Icon,
  },
})
export default class DoubleRow extends Mixins(Mixin) {
  @Prop({
    type: Array,
    default: () => {
      return [];
    },
  })
  public imgList!: Array<cms.SubjectImgUrlDto>;
  leftData: Array<cms.SubjectImgUrlDto> = [];
  rightData: Array<cms.SubjectImgUrlDto> = [];
  mounted(): void {
    this.imgList.forEach((item, index) => {
      if (index % 2 === 0) {
        this.leftData.push(item);
      } else {
        this.rightData.push(item);
      }
    });
  }
  goDetail(item: cms.SubjectImgUrlDto): void {
    this.goToJump(item.keyword, item.commonId, item.linkTypeEnum, item.url);
  }
}
