
import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins, Prop } from "vue-property-decorator";
import LinkCouponItem from "@/components/Tickets/Ticketltem.vue";
import { Popup, Dialog } from "vant";
@Component({
  components: {
    LinkCouponItem,
    [Popup.name]: Popup,
    [Dialog.Component.name]: Dialog.Component,
  },
})
export default class LinkCenter extends Mixins(Mixin) {
  @Prop({ default: "" }) public CouponList!: Array<cms.CouponDetailDto>;
  public couponId = 0;
  public showDetail = false;
  public text = "";
  public couponType = "";
  public effectTime = "";
  public isRealName: boolean | undefined = false; // 当前用户是否实名制
  public hasClickReceiveBtn = false;
  dealDate(
    type?: number,
    endTimeStr?: string,
    startTimeStr?: string,
    startTime?: string,
    endTime?: string,
    effectiveDays?: number
  ): string {
    let timer = "";
    let nowTime = new Date().getTime();
    if (type === 1) {
      if (startTime && endTime) {
        if (Number(nowTime) > Number(startTimeStr)) {
          timer = `有效期至${this.timestampFormat(
            Number(endTimeStr),
            "YYYY-MM-DD HH:mm"
          )}`;
        } else {
          timer = `有效期${startTime}至${endTime}`;
        }
      } else {
        timer = "";
      }
    } else {
      if (effectiveDays) {
        timer = `领取后${effectiveDays}天内有效`;
      } else {
        timer = "";
      }
    }

    return timer;
  }
  goDetail(item: cms.CouponDetailDto): void {
    this.$router.push(`/coupon-detail?couponId=${item.couponId}`);
  }

  /**  领取优惠券
   * 领取时有的会要求登录
   * 领取时有的要求实名领取
   */
  async onClick(item: cms.CouponDetailDto): Promise<void> {
    if (this.hasClickReceiveBtn) return;
    this.hasClickReceiveBtn = true;
    setTimeout(() => {
      this.hasClickReceiveBtn = false; //防止多次调用接口
    }, 1500);

    if (this.UserModule.isLogin) {
      if (item.receivedType === "1") {
        if (item.cardApprove && !this.isRealName) {
          // 再次确认实名状态(解决和原生、小程序实名认证出现的交互问题)
          await this.getUserRealName();
          if (!this.isRealName) {
            Dialog.alert({
              message: "该优惠券需要实名认证，您还未认证，是否前往认证？",
              showCancelButton: true,
              confirmButtonText: "前往认证",
              cancelButtonText: "否",
            }).then(() => {
              this.jsBridge("needRealName", "", () => {
                this.$router.push(`/personal-data/real-name/1`);
              });
            });
          } else {
            this.receiveCoupons(item);
          }
        } else {
          this.receiveCoupons(item);
        }
      } else if (item.receivedType === "2") {
        this.goUse(item);
      } else if (item.receivedType === "3") {
        if (item.myReceivedCount! > 0) {
          this.goUse(item);
        }
      }
    } else {
      this.jsBridge("needLogin", "", () => {
        this.RouteRecordModule.GO_TO_LOGIN_PAGE_PERSIST({
          router: this.$router,
          newPage: true,
          goBackAfterLogin: true,
        });
      });
    }
  }

  async getUserRealName(): Promise<void> {
    await this.$api.memberApi.setting.getAccountInfo(({ data }) => {
      this.isRealName = data.authentication;
    });
  }

  receiveCoupons(item: cms.CouponDetailDto): void {
    if (item.receivedType !== "3") {
      this.$api.goodApi.coupon.receiveCoupon(
        item.couponId!,
        { activityId: "" },
        (data) => {
          if (data.data) {
            this.$emit("refresh");
            Dialog.alert({
              message: "领取成功，请前往保利票务渠道使用",
            });
          }
        }
      );
    }
    if (item.receivedType === "3") {
      this.$toast("券被抢光了，去看看其他优惠吧~");
    }
  }
  goUse(item: cms.CouponDetailDto): void {
    this.couponType = this.getCouponType(
      item.orderAmountType!,
      item.orderAmount!,
      item.batchClass!,
      item.parValue!
    );
    this.$router.push(
      `/coupons-use?couponId=${item.couponId}&couponType=${this.couponType}`
    );
  }
  getCouponType(
    orderAmountType: number,
    orderAmount: number,
    batchClass: string,
    parValue: number
  ): string {
    let text = "";
    if (Number(batchClass) === 6) {
      if (orderAmountType === 1) {
        text = `${parValue}折优惠券`;
      } else {
        text = `满${orderAmount}打${parValue}折优惠券`;
      }
    } else {
      if (orderAmountType === 1) {
        text = `${parValue}元无门槛优惠券`;
      } else {
        text = `满${orderAmount}减${parValue}优惠券`;
      }
    }

    return text;
  }
  /*
   * 订单金额提示
   */
  dealAmount(type?: number, Amount?: number): string {
    let name = "";
    if (type === 1) {
      name = "不限金额";
    } else {
      name = `满${Amount}可用`;
    }
    return name;
  }
  /*
   * 处理按钮
   */
  dealName(
    type?: string,
    receivedCount?: number
  ): {
    status: number;
    name: string;
  } {
    let data = {
      status: 1,
      name: "",
    };
    if (type === "1") {
      data.status = 1;
      data.name = "领取";
    } else if (type === "2") {
      data.name = "去使用";
      data.status = 1;
    } else if (type === "3") {
      /*
            receivedType===3时为领光有两种状态
            如果我的领取数量大于0 显示去使用，否则显示领光
            */
      if (receivedCount && receivedCount > 0) {
        data.name = "去使用";
        data.status = 1;
      } else {
        data.status = 3;
        data.name = "领光";
      }
    }
    return data;
  }

  /*
     * 处理按钮下的提示
     rType:receiveQuantityType(领取数量类型，1：不限制，2:每人限领多少张)
     vType:receivedType(领取类型，1可领，2已领，3领光)
     count:receivedCount(用户领取数量)
     quantity:receiveQuantity(限制领取数量)
     当rType为1不限制用户领取时，
        如果vType为1  展示用户"持有count张"；如果vType为2 展示用户"持有count张"；如果vType为3 如果用户领取了展示已领，持有n张 如果没有领，展示领光，按钮置灰
    当rType为2时限制用户领取时
    如果vType为1 展示用户最大可领取quantity数量；如果vType为2 展示用户"持有count张/quantity张"；如果vType为3 如果用户领取了展示已领，持有n张 如果没有领，展示领光，按钮置灰
     */
  dealQuantity(
    rType?: number,
    vType?: string,
    count?: number,
    quantity?: number
  ): string {
    let res = "";
    if (rType === 1) {
      //领取
      if (vType === "1") {
        //不限制
        res = count ? `持有${count}张` : "";
      } else if (vType === "2") {
        res = count ? `持有${count}张` : "";
      } else if (vType === "3") {
        if (count! > 0) {
          res = `持有${count}张`;
        } else {
          res = "";
        }
      }
    } else {
      //使用
      if (vType === "1") {
        if ((count as number) > 0) {
          res = `持有${count}/${quantity}张`;
        } else {
          res = quantity ? `可领取${quantity}张` : "";
        }
      } else if (vType === "2") {
        res = `持有${count}/${quantity}张`;
      } else if (vType === "3") {
        if (count! > 0) {
          res = `持有${count}/${quantity}张`;
        } else {
          res = "";
        }
      }
    }
    return res;
  }
}
