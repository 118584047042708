
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { NavBar, Image, Icon, Row, Swipe, SwipeItem, Tab, Tabs } from "vant";
import ShopItem from "@/views/Mall/Details/ShopItem.vue";
@Component({
  components: {
    [NavBar.name]: NavBar,
    [Image.name]: Image,
    [Icon.name]: Icon,
    [Row.name]: Row,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    ShopItem,
  },
})
export default class waterfallLayout extends Mixins(Mixin) {
  @Prop({
    type: Array,
  })
  public classifyList!: good.CommodityDto[]; //商品信息
  leftList: good.CommodityDto[] = []; //左边列表信息
  rightList: good.CommodityDto[] = []; //右边列表信息
  leftHight = 0;
  rightHight = 0;
  @Watch("classifyList", { immediate: true, deep: true })
  updateClassify(): void {
    this.leftHight = 0;
    this.rightHight = 0;
    this.leftList = [];
    this.rightList = [];
    this.dealInfo(this.classifyList);
  }

  goMallDetails(itemInfo: good.CommodityDto): void {
    this.$emit("goMallDetails", itemInfo);
  }
  //处理瀑布流数据
  dealInfo(allData: good.CommodityDto[]): void {
    //定义两个临时的变量来记录左右两栏的高度，避免频繁调用setData方法
    let leftH = this.leftHight;
    let rightH = this.rightHight;
    let leftData = [];
    let rightData = [];
    let imgHeight = 335;
    for (let i = 0; i < allData.length; i++) {
      let titleHeight = allData[i].commodityTitle
        ? Math.ceil(Number(allData[i].commodityTitle!.length - 1) / 10) * 36
        : 0;
      let couponsLabelHight = allData[i].commodityLabel ? 28 : 0;
      let priceHeight = 0;
      let lowestMemberPrices: any[] = [];
      if (allData[i].lowestMemberPrices) {
        lowestMemberPrices = allData[i].lowestMemberPrices!.filter((item) => {
          if (item.memberGradeId === this.UserModule.memberLevelId) {
            return item.memberGradeId === this.UserModule.memberLevelId;
          } else {
            return item.memberGradeId === "0";
          }
        });
      }
      if (lowestMemberPrices.length) {
        let info = lowestMemberPrices[0];
        if (info.amount! > 0 && info.integral! > 0 && info.printing! > 0) {
          priceHeight = 76;
        } else {
          priceHeight = 38;
        }
      }
      let currentItemHeight =
        titleHeight + couponsLabelHight + imgHeight + priceHeight; //当前card整个的高
      if (leftH == rightH || leftH < rightH) {
        // 判断左右两侧当前的累计高度，来确定item应该放置在左边还是右边
        leftData.push({
          ...allData[i],
          lowestMemberPrices: lowestMemberPrices,
        });
        leftH += currentItemHeight;
      } else {
        rightData.push({
          ...allData[i],
          lowestMemberPrices: lowestMemberPrices,
        });
        rightH += currentItemHeight;
      }
    }

    leftData = this.leftList.concat(leftData);
    rightData = this.rightList.concat(rightData);
    // 更新左右两栏的数据以及累计高度
    this.leftList = leftData;
    this.rightList = rightData;
    this.leftHight = leftH;
    this.rightHight = rightH;
  }
}
