
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Swipe, SwipeItem, Cell, CellGroup, Image, Row } from "vant";

@Component({
  components: {
    [SwipeItem.name]: SwipeItem,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Image.name]: Image,
    [Swipe.name]: Swipe,
    [Row.name]: Row,
  },
})
export default class ShopItem extends Mixins(Mixin) {
  @Prop({
    type: Object,
    default: () => {
      return {};
    },
  })
  public itemInfo!: good.CommodityDto;

  goDetails(itemInfo: good.CommodityDto): void {
    this.$emit("goMallDetails", itemInfo);
  }
}
