
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Row, Image, Col, Icon } from "vant";
@Component({
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Image.name]: Image,
    [Icon.name]: Icon,
  },
})
export default class ShowItem extends Mixins(Mixin) {
  @Prop({
    type: Object,
    default: () => {
      return {};
    },
  })
  public itemInfo!: good.RecommendProductDto;
  goDetails(itemInfo: good.RecommendProductDto): void {
    this.$emit("goDetails", itemInfo);
  }
}
