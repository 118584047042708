
import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Image, PullRefresh, Toast } from "vant";
import ActorList from "@/views/ProjectManagement/components/ActorList.vue";
import WonderfulList from "@/views/ProjectManagement/components/Wonderful.vue";
import ProBriefing from "@/views/ProjectManagement/components/Briefing.vue";
import ProRecommend from "@/views/ProjectManagement/components/ProRecommend.vue";
import TopImg from "@/views/ProjectManagement/components/TopImg.vue";
import SingleRow from "@/views/ProjectManagement/components/SingleRow.vue";
import DoubleRow from "@/views/ProjectManagement/components/DoubleRow.vue";
import SlideSwipe from "@/views/ProjectManagement/components/SlideSwipe.vue";

@Component({
  components: {
    [Image.name]: Image,
    [PullRefresh.name]: PullRefresh,
    ActorList,
    WonderfulList,
    ProBriefing,
    ProRecommend,
    TopImg,
    SingleRow,
    DoubleRow,
    SlideSwipe,
  },
})
export default class ProjectManagement extends Mixins(Mixin) {
  id = "";
  active = 0;
  isShowBack = true;
  isLoading = false;
  dataInfo: cms.SubjectDto = {
    shareDescribe: "",
    shareImg: "",
    specialTitle: "",
  };

  mounted(): void {
    this.isShowBack = this.$route.query.isTab ? false : true; //是否是首页的tab,是不展示返回按钮
    this.id = this.$route.query.id ? String(this.$route.query.id) : "";
    this.getData();
  }

  onClickLeft(): void {
    this.$router.go(-1);
  }

  onRefresh(): void {
    this.isLoading = true;
    this.getData();
  }

  getData(): void {
    this.$api.cmsApi.subject.findSubjectInfoById(
      this.id,
      ({ data }) => {
        this.isLoading = false;
        this.dataInfo = data;
        window.document.title = data.specialTitle || "爱演出 爱生活-保利票务";
        this.SpecialManagementModule.SET_Special({
          commodityId: data.commodityId,
          commodityKeywords: data.commodityKeywords,
          productId: data.productId,
          productKeywords: data.productKeywords,
          subjectCommodity: data.subjectCommodity,
          subjectProduct: data.subjectProduct,
          openButtonImg: data.openButtonImg,
          closeButtonImg: data.closeButtonImg,
        });
      },
      () => {
        this.isLoading = false;
        window.document.title = "爱演出 爱生活-保利票务";
      }
    );
  }
}
