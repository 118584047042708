var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"recommend"},[(_vm.contentImg)?_c('div',{staticClass:"r-title"},[_c('van-image',{attrs:{"fit":_vm.imageFit,"width":"5.8remrem","height":"100%","src":_vm.contentImg}})],1):_vm._e(),_c('div',{staticClass:"r-main"},[(
        _vm.SpecialManagementModule.subjectCommodity &&
        _vm.SpecialManagementModule.subjectProduct
      )?[_c('div',[_c('van-tabs',{attrs:{"line-width":"0.37rem","title-inactive-color":"#333","title-active-color":_vm.titleColor || '#111111'},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('van-tab',{attrs:{"title":"演出"}},[_c('only-Show',{attrs:{"content-type":_vm.contentType}})],1),_c('van-tab',{attrs:{"title":"商品"}},[_c('only-mall',{attrs:{"content-type":_vm.contentType}})],1)],1)],1)]:_vm._e(),(
        _vm.SpecialManagementModule.subjectCommodity &&
        !_vm.SpecialManagementModule.subjectProduct
      )?_c('only-mall',{attrs:{"content-type":_vm.contentType}}):_vm._e(),(
        !_vm.SpecialManagementModule.subjectCommodity &&
        _vm.SpecialManagementModule.subjectProduct
      )?_c('only-Show',{attrs:{"content-type":_vm.contentType}}):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }